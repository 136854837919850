import { useState } from "react";
import { Modal, Form, Select, Button, message, Upload, Spin, Table } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { addArrayDataToRealm } from "../../../../services/realmServices";
import { excelDateToJSDate, formatDateToJSDateToShow } from "../../../../services/common";
import * as XLSX from "xlsx";
import AntTable from "../../../../components/common/AntTable";

const typeData = [
  { value: "income", label: "Thu nhập" },
  { value: "spend", label: "Chi tiêu" },
];

const AddBalanceSheet = ({ isOpenModal, onClose }) => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const width = "90%";

  const handleCloseModal = (isReload) => {
    setData([]);
    onClose(isReload);
  };

  const onSubmit = () => {
    setSubmiting(true);

    form
      .validateFields()
      .then(async (values) => {
        const convertedData = data.map((e) => {
          return {
            type_code: e.type_code.trim(),
            description: e.description.trim(),
            value: e.value,
            date: excelDateToJSDate(e.dateOriginal).toISOString(),
          };
        });

        try {
          await addArrayDataToRealm(values.type, convertedData);
          message.success("Thêm dữ liệu thành công!");
          setData([]);
        } catch (error) {
          message.error("Thêm dữ liệu thất bại!");
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  // select
  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // upload
  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Prevent the default upload behavior
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const converedData = jsonData.map((e) => {
          return {
            ...e,
            date: formatDateToJSDateToShow(e.date),
            dateOriginal: e.date,
          };
        });

        setData(converedData);
      };

      reader.readAsBinaryString(file);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "removed") {
        message.success(`${info.file.name} file removed successfully.`);
      }
    },
  };

  const columns = [
    {
      title: "type_code",
    },
    {
      title: "description",
    },
    {
      title: "value",
    },
    {
      title: "date",
    },
  ];

  return (
    <Modal
      title={<h3>Thêm mới danh sách chi tiêu</h3>}
      centered
      open={isOpenModal}
      onOk={onSubmit}
      onCancel={() => handleCloseModal(false)}
      width={width}>
      <Form form={form} layout="inline">
        <Form.Item
          label="Loại dữ liệu"
          name="type"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn loại dữ liệu!",
            },
          ]}>
          <Select
            showSearch
            placeholder="Chọn loại dữ liệu"
            filterOption={filterOption}
            options={typeData}
            style={{ width: 150 }}
          />
        </Form.Item>
        <Form.Item
          name="data-upload"
          label="File import (excel)"
          rules={[{ required: true, message: "Vui lòng chọn file!" }]}>
          <Upload {...props}>
            <Button loading={submiting} size="small" icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      {data.length === 0 && (
        <Spin spinning={submiting}>
          <h4>Mẫu import</h4>
          <Table columns={columns} dataSource={[]} />
        </Spin>
      )}

      {data.length > 0 && (
        <Spin spinning={submiting}>
          <h4>Danh sách danh mục đã thêm</h4>
          <AntTable data={data} />
        </Spin>
      )}
    </Modal>
  );
};
export default AddBalanceSheet;
