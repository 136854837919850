// const endpoint = "https://fifree-supabase-service.vercel.app/api";
const endpoint = "http://localhost:8080/api";

const fetchBase = async (method, url, body, accessToken) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    if (response.status === 204) {
      return {
        status: response.status,
      };
    }

    const results = await response.json();
    return results;
  } catch (error) {
    return { status: 204, error: error };
  }
};

//=================== FUNDS ===================
// GET api/funds
export const getAllFunds = async () => {
  return fetchBase("GET", `${endpoint}/funds`);
};

// GET api/funds
export const getFundPortfolioStats = async () => {
  return fetchBase("GET", `${endpoint}/funds-portfolio-stats`);
};
