import { useState, useEffect, useCallback } from "react";
import { Table, Space, Input, Spin, Flex, Card, Radio } from "antd";
import { fetchDataWithGroupOrder } from "../../../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage } from "../../../../services/common";
import ReactECharts from "echarts-for-react";

const viewTypes = {
  chart: "chart",
  table: "table",
};

const typeChart = "line"; //bar

const StockStatistic = ({ stock }) => {
  const [loading, setLoading] = useState(true);
  const [dataGroup, setDataGroup] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [type, setType] = useState(viewTypes.chart);

  // filter option
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (stock) setSearchValue(stock);
    else setSearchValue("FPT");
  }, [stock]);

  const fetchGroupDataFromRealm = useCallback(async (searchValue) => {
    try {
      let queryObject = [
        { $match: { stock_code: searchValue } },
        {
          $group: {
            _id: {
              stock_code: "$stock_code",
              month: "$month",
              year: "$year",
            },
            quantity: { $sum: "$quantity" },
            value: { $sum: "$value" },
            portfolio_rate: { $sum: "$portfolio_rate" },
            organizations: {
              $push: {
                fund_code: "$fund_code",
              },
            },
            count: { $sum: 1 },
          },
        },
        { $sort: { "_id.year": 1, "_id.month": 1 } },
      ];

      setLoading(true);
      const result = await fetchDataWithGroupOrder("funds_portfolio", queryObject);
      if (result.length === 0) {
        setDataGroup([]);
        setDataChart({});
        return;
      }

      const convertedDataTable = [];
      const convertedDataChart = { xAxis: [], series: [] };

      result.forEach((e, index) => {
        convertedDataTable.push({
          key: index.toString(),
          time: `${e._id.month}/${e._id.year % 1000}`,
          stock_code: e._id.stock_code,
          count: e.count,
          organizations: e.organizations.map((e) => e.fund_code).join(", "),
          quantity: e.quantity,
          value: e.value,
          portfolio_rate: e.portfolio_rate,
        });

        convertedDataChart.xAxis.push(`${e._id.month}/${e._id.year % 1000}`);
        convertedDataChart.series.push((e.value / 1000000000).toFixed(0));
      });

      const optionsChart = {
        xAxis: {
          type: "category",
          data: convertedDataChart.xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: convertedDataChart.series,
            type: typeChart,
            barWidth: "50%",
            areaStyle: {},
          },
        ],
      };

      setDataGroup(convertedDataTable);
      setDataChart(optionsChart);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGroupDataFromRealm(searchValue);
  }, [searchValue, fetchGroupDataFromRealm]);

  const columns_group = [
    {
      title: <span className="text-secondary-color">Tháng</span>,
      dataIndex: "time",
      key: "time",
      align: "center",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">Tần suất</span>,
      dataIndex: "count",
      key: "count",
      align: "center",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">Giá trị (tỉ)</span>,
      dataIndex: "value",
      key: "value",
      align: "center",
      ellipsis: true,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000000000))}</b>,
    },
    {
      title: <span className="text-secondary-color">TT (%)</span>,
      dataIndex: "portfolio_rate",
      key: "portfolio_rate",
      align: "center",
      ellipsis: true,
      render: (numberString) => <b>{formatToPercentage(numberString)}</b>,
    },
  ];

  return (
    <Card>
      <Flex justify="space-between" align="end">
        <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Thống kê giá trị: {searchValue}</span>
        <Space size={12}>
          <Input
            placeholder="Tìm kiếm..."
            size="small"
            style={{ height: "2rem", fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
          <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
            <Radio.Button value="chart">Chart</Radio.Button>
            <Radio.Button value="table">Table</Radio.Button>
          </Radio.Group>
          {loading && <Spin />}
        </Space>
      </Flex>
      <br />

      {type === viewTypes.table && (
        <Table
          columns={columns_group}
          dataSource={dataGroup}
          className="ant-border-space"
          pagination={false}
          scroll={{
            y: "70vh",
          }}
        />
      )}

      {type === viewTypes.chart && dataChart && (
        <ReactECharts style={{ height: "76vh", width: "100%" }} option={dataChart} />
      )}
    </Card>
  );
};
export default StockStatistic;
