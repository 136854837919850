export const mongoCollections = {
  funds: "funds",
  fundsPortfolio: "funds_portfolio",
  fundsPortfolioGroupByStock: "funds_portfolio_group_by_stock",
  fundsPortfolioGroupByFund: "funds_portfolio_group_by_fund",
  income: "income",
  spend: "spend",
};

export const mongoPivotScript = [
  { $match: { year: 2024, "data_fund.fund_code": "DCDS" } },
  {
    $group: {
      _id: {
        stock_code: "$stock_code",
        fund_code: "$data_fund.fund_code",
        month: "$month",
      },
      totalQuantity: { $sum: "$quantity" },
    },
  },

  {
    $group: {
      _id: {
        stock_code: "$_id.stock_code",
        fund_code: "$_id.fund_code",
      },
      fundData: {
        $push: {
          month: { $toString: "$_id.month" },
          quantity: "$totalQuantity",
        },
      },
    },
  },

  {
    $project: {
      _id: 0,
      stock_code: "$_id.stock_code",
      fund_code: "$_id.fund_code",
      fundData: {
        $arrayToObject: {
          $map: {
            input: "$fundData",
            as: "fd",
            in: { k: "$$fd.month", v: "$$fd.quantity" },
          },
        },
      },
    },
  },

  {
    $replaceRoot: {
      newRoot: {
        $mergeObjects: ["$$ROOT", "$fundData"],
      },
    },
  },

  {
    $addFields: {
      1: { $ifNull: ["$1", null] },
      2: { $ifNull: ["$2", null] },
      3: { $ifNull: ["$3", null] },
      4: { $ifNull: ["$4", null] },
      5: { $ifNull: ["$5", null] },
      6: { $ifNull: ["$6", null] },
      7: { $ifNull: ["$7", null] },
      8: { $ifNull: ["$8", null] },
      9: { $ifNull: ["$9", null] },
      10: { $ifNull: ["$10", null] },
      11: { $ifNull: ["$11", null] },
      12: { $ifNull: ["$12", null] },
    },
  },

  {
    $project: { fundData: 0 },
  },
];

export const mongoFundsWithCountStockScript = [
  {
    $group: {
      _id: {
        fund_code: "$fund_code",
        month: "$month",
      },
      countStock: { $sum: 1 },
      totalQuantity: { $sum: "$quantity" },
      totalPrice: { $sum: "$market_price" },
      totalPortfolioRate: {
        $sum: "$portfolio_rate",
      },
    },
  },

  {
    $group: {
      _id: {
        stock_code: "$_id.stock_code",
        fund_code: "$_id.fund_code",
      },
      fundData: {
        $push: {
          month: { $toString: "$_id.month" },
          count_stock: "$countStock",
          quantity: "$totalQuantity",
          market_price: "$totalPrice",
          portfolio_rate: "$totalPortfolioRate",
        },
      },
    },
  },

  {
    $project: {
      _id: 0,
      stock_code: "$_id.stock_code",
      fund_code: "$_id.fund_code",
      fundData: {
        $arrayToObject: {
          $map: {
            input: "$fundData",
            as: "fd",
            in: {
              k: "$$fd.month",
              v: {
                count_stock: "$$fd.count_stock",
                quantity: "$$fd.quantity",
                market_price: "$$fd.market_price",
                portfolio_rate: "$$fd.portfolio_rate",
              },
            },
          },
        },
      },
    },
  },

  {
    $replaceRoot: {
      newRoot: {
        $mergeObjects: ["$$ROOT", "$fundData"],
      },
    },
  },

  {
    $addFields: {
      1: { $ifNull: ["$1", null] },
      2: { $ifNull: ["$2", null] },
      3: { $ifNull: ["$3", null] },
      4: { $ifNull: ["$4", null] },
      5: { $ifNull: ["$5", null] },
      6: { $ifNull: ["$6", null] },
      7: { $ifNull: ["$7", null] },
      8: { $ifNull: ["$8", null] },
      9: { $ifNull: ["$9", null] },
      10: { $ifNull: ["$10", null] },
      11: { $ifNull: ["$11", null] },
      12: { $ifNull: ["$12", null] },
    },
  },

  {
    $project: { fundData: 0 },
  },
];

export const mongoFundGroupCountScript = [
  {
    $group: {
      _id: "$stock_code",
      fund_code: { $push: "$fund_code" },

      T1Quantity: {
        $sum: "$1.quantity",
      },
      T1PortfolioRate: {
        $sum: "$1.portfolio_rate",
      },
      T1marketPrice: {
        $sum: "$1.market_price",
      },
      T2Quantity: {
        $sum: "$2.quantity",
      },
      T2PortfolioRate: {
        $sum: "$2.portfolio_rate",
      },
      T2marketPrice: {
        $sum: "$2.market_price",
      },
      T3Quantity: {
        $sum: "$3.quantity",
      },
      T3PortfolioRate: {
        $sum: "$3.portfolio_rate",
      },
      T3marketPrice: {
        $sum: "$3.market_price",
      },
      T4Quantity: {
        $sum: "$4.quantity",
      },
      T4PortfolioRate: {
        $sum: "$4.portfolio_rate",
      },
      T4marketPrice: {
        $sum: "$4.market_price",
      },
      T5Quantity: {
        $sum: "$5.quantity",
      },
      T5PortfolioRate: {
        $sum: "$5.portfolio_rate",
      },
      T5marketPrice: {
        $sum: "$5.market_price",
      },
      T6Quantity: {
        $sum: "$6.quantity",
      },
      T6PortfolioRate: {
        $sum: "$6.portfolio_rate",
      },
      T6marketPrice: {
        $sum: "$6.market_price",
      },
      T7Quantity: {
        $sum: "$7.quantity",
      },
      T7PortfolioRate: {
        $sum: "$7.portfolio_rate",
      },
      T7marketPrice: {
        $sum: "$7.market_price",
      },
      T8Quantity: {
        $sum: "$8.quantity",
      },
      T8PortfolioRate: {
        $sum: "$8.portfolio_rate",
      },
      T8marketPrice: {
        $sum: "$8.market_price",
      },
      T9Quantity: {
        $sum: "$9.quantity",
      },
      T9PortfolioRate: {
        $sum: "$9.portfolio_rate",
      },
      T9marketPrice: {
        $sum: "$9.market_price",
      },
      T10Quantity: {
        $sum: "$10.quantity",
      },
      T10PortfolioRate: {
        $sum: "$10.portfolio_rate",
      },
      T10marketPrice: {
        $sum: "$10.market_price",
      },
      T11Quantity: {
        $sum: "$11.quantity",
      },
      T11PortfolioRate: {
        $sum: "$11.portfolio_rate",
      },
      T11marketPrice: {
        $sum: "$11.market_price",
      },
      T12Quantity: {
        $sum: "$12.quantity",
      },
      T12PortfolioRate: {
        $sum: "$12.portfolio_rate",
      },
      T12marketPrice: {
        $sum: "$12.market_price",
      },
    },
  },
  {
    $project: {
      _id: 0,
      stock_code: "$_id",
      fund_code: "$fund_code",
      1: {
        quantity: "$T1Quantity",
        portfolio_rate: "$T1PortfolioRate",
        market_price: "$T1marketPrice",
      },
      2: {
        quantity: "$T2Quantity",
        portfolio_rate: "$T2PortfolioRate",
        market_price: "$T2marketPrice",
      },
      3: {
        quantity: "$T3Quantity",
        portfolio_rate: "$T3PortfolioRate",
        market_price: "$T3marketPrice",
      },
      4: {
        quantity: "$T4Quantity",
        portfolio_rate: "$T4PortfolioRate",
        market_price: "$T4marketPrice",
      },
      5: {
        quantity: "$T5Quantity",
        portfolio_rate: "$T5PortfolioRate",
        market_price: "$T5marketPrice",
      },
      6: {
        quantity: "$T6Quantity",
        portfolio_rate: "$T6PortfolioRate",
        market_price: "$T6marketPrice",
      },
      7: {
        quantity: "$T7Quantity",
        portfolio_rate: "$T7PortfolioRate",
        market_price: "$T7marketPrice",
      },
      8: {
        quantity: "$T8Quantity",
        portfolio_rate: "$T8PortfolioRate",
        market_price: "$T8marketPrice",
      },
      9: {
        quantity: "$T9Quantity",
        portfolio_rate: "$T9PortfolioRate",
        market_price: "$T9marketPrice",
      },
      10: {
        quantity: "$T10Quantity",
        portfolio_rate: "$T10PortfolioRate",
        market_price: "$T10marketPrice",
      },
      11: {
        quantity: "$T11Quantity",
        portfolio_rate: "$T11PortfolioRate",
        market_price: "$T11marketPrice",
      },
      12: {
        quantity: "$T12Quantity",
        portfolio_rate: "$T12PortfolioRate",
        market_price: "$T12marketPrice",
      },
    },
  },
];

export const mongoFundGroupCountScript_Group = [
  {
    $group: {
      _id: "$stock_code",
      fund_code: { $push: "$fund_code" },

      T1Quantity: {
        $sum: "$1.quantity",
      },

      T2Quantity: {
        $sum: "$2.quantity",
      },

      T3Quantity: {
        $sum: "$3.quantity",
      },

      T4Quantity: {
        $sum: "$4.quantity",
      },

      T5Quantity: {
        $sum: "$5.quantity",
      },

      T6Quantity: {
        $sum: "$6.quantity",
      },

      T7Quantity: {
        $sum: "$7.quantity",
      },

      T8Quantity: {
        $sum: "$8.quantity",
      },

      T9Quantity: {
        $sum: "$9.quantity",
      },

      T10Quantity: {
        $sum: "$10.quantity",
      },

      T11Quantity: {
        $sum: "$11.quantity",
      },

      T12Quantity: {
        $sum: "$12.quantity",
      },
    },
  },
  {
    $project: {
      _id: 0,
      stock_code: "$_id",
      fund_code: "$fund_code",
      1: {
        quantity: "$T1Quantity",
      },
      2: {
        quantity: "$T2Quantity",
      },
      3: {
        quantity: "$T3Quantity",
      },
      4: {
        quantity: "$T4Quantity",
      },
      5: {
        quantity: "$T5Quantity",
      },
      6: {
        quantity: "$T6Quantity",
      },
      7: {
        quantity: "$T7Quantity",
      },
      8: {
        quantity: "$T8Quantity",
      },
      9: {
        quantity: "$T9Quantity",
      },
      10: {
        quantity: "$T10Quantity",
      },
      11: {
        quantity: "$T11Quantity",
      },
      12: {
        quantity: "$T12Quantity",
      },
    },
  },
];
