import { useState, useEffect, useCallback } from "react";
import { Table, Select, Input, Spin, Flex, InputNumber, Checkbox } from "antd";
import { fetchDataWithGroupOrder } from "../../../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage } from "../../../../services/common";
import { useFundsContext } from "../../../../context/FundsContext";
import { mongoCollections, mongoFundGroupCountScript_Group } from "../../../../utils/mongoScript";
import EChartCustom from "../../../../components/common/EChartCustom";
import useWindowSize from "../../../../hooks/useWindowSize";

const chartTypes = [
  {
    value: "line",
    label: "Line",
  },
  {
    value: "bar",
    label: "Bar",
  },
];

const queryTypesSample = {
  quantity: "quantity",
  value: "value",
  portfolio_rate: "portfolio_rate",
};

const queryTypes = [
  {
    value: "quantity",
    label: "Số lượng CP (n)",
  },
  {
    value: "market_price",
    label: "Gía thị trường (n)",
  },
  {
    value: "portfolio_rate",
    label: "Tỉ trọng (%)",
  },
];

const getMonthsHeader = (type) => {
  const header = [];
  for (let i = 1; i <= 11; i++) {
    header.push({
      title: `T${i.toString()}`,
      dataIndex: `${i.toString()}`,
      key: `${i.toString()}`,
      align: "center",
      ellipsis: true,
      render: (data) => {
        if (!data)
          return (
            <Flex vertical>
              <b>0</b>
              <b style={{ color: "white", fontSize: "0.6rem" }}>0</b>
            </Flex>
          );
        let dataShow = data[`${type}`];

        if (type === queryTypesSample.quantity) dataShow = formatNumberWithCommas(Math.floor(dataShow / 1000));
        else if (type === queryTypesSample.portfolio_rate) {
          dataShow = formatToPercentage(dataShow);
        } else {
          dataShow = formatNumberWithCommas(Math.floor(dataShow / 1000));
        }

        if (data.prev) {
          const change = Math.floor((data[`${type}`] - data.prev[`${type}`]) / 1000);
          if (change > 0) {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "green", fontSize: "0.6rem" }}>+{change}</b>
              </Flex>
            );
          } else if (change === 0) {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "green", fontSize: "0.6rem" }}>--</b>
              </Flex>
            );
          } else {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "red", fontSize: "0.6rem" }}>{change}</b>
              </Flex>
            );
          }
        } else {
          return (
            <Flex vertical>
              <b>{dataShow}</b>
              <b style={{ color: "green", fontSize: "0.6rem" }}>+{dataShow}</b>
            </Flex>
          );
        }
      },
    });
  }

  return header;
};

const getGrowIndicator = (data) => {
  const currentMonth = 11;
  let count = 0;

  for (let i = currentMonth; i > 1; i--) {
    if (data[`${i}`] && data[`${i}`].quantity) {
      if (data[`${i - 1}`] && data[`${i - 1}`].quantity) {
        if (data[`${i}`].quantity > data[`${i - 1}`].quantity) {
          count++;
        } else {
          return count;
        }
      } else {
        return count + 1;
      }
    } else return count;
  }

  return count;
};

const AdvancePortfolios = ({ isReload }) => {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [checked, setChecked] = useState(false);

  const { fundsContext } = useFundsContext();

  // const defaultYear = new Date().getFullYear();
  const defaultYear = 2024;

  const [year, setYear] = useState(defaultYear);
  const [fundSelected, setFundSelected] = useState(null);
  const [type, setType] = useState("quantity");
  const [searchValue, setSearchValue] = useState("");
  const [chartType, setChartType] = useState("line");

  const fetchDataFromRealm = useCallback(async (year, fundCode, searchByStockCode, checked) => {
    try {
      let queryObject = {};
      let aggregateArray = [
        {
          $lookup: {
            from: "funds",
            localField: "fund_code",
            foreignField: "fund_code",
            as: "fund_data",
          },
        },
        {
          $unwind: "$fund_data",
        },
        {
          $match: {
            "fund_data.status": 1,
          },
        },
        {
          $project: {
            fund_data: 0,
          },
        },
      ];

      if (fundCode) queryObject = { ...queryObject, fund_code: fundCode };
      if (year) queryObject = { ...queryObject, year };
      if (searchByStockCode) queryObject = { ...queryObject, stock_code: { $regex: searchByStockCode, $options: "i" } };

      if (queryObject) aggregateArray = [{ $match: queryObject }, ...aggregateArray];
      if (checked) aggregateArray = [...aggregateArray, ...mongoFundGroupCountScript_Group];

      setLoading(true);
      const result = await fetchDataWithGroupOrder(mongoCollections.fundsPortfolioGroupByStock, aggregateArray);
      if (result.length === 0) {
        setData([]);
      }

      const converedData = [];

      for (let i = 0; i < result.length; i++) {
        const temp = result[i];
        for (let j = 11; j >= 1; j--) {
          if (temp[j]) {
            if (i === 1) {
              temp[j].prev = temp[j];
            } else if (temp[j - 1]) {
              temp[j].prev = temp[j - 1];
            }
          }
        }
        if (i === 0) console.log(temp);

        const growIndicator = getGrowIndicator(temp);

        converedData.push({
          ...temp,
          growIndicator,
          key: i.toString(),
          index: i + 1,
          stock_code: temp.stock_code,
          fund_code: !checked
            ? temp.fund_code
            : `(${temp.fund_code.length}) ${temp.fund_code.splice(0, 2).join(", ")} ${
                temp.fund_code.length > 3 ? ",.." : ""
              }`,
        });
      }
      setData(converedData);
      setOriginalData(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataFromRealm(year, fundSelected, searchValue, checked);
  }, [isReload, year, fundSelected, searchValue, checked, fetchDataFromRealm]);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleCheckedChange = () => {
    if (!checked) {
      setType(queryTypesSample.quantity);
    }

    setChecked((prev) => !prev);
  };

  const handleFilterMonthIncrement = (value) => {
    if (!value) return;
    const newDataShow = originalData.filter((e) => e.growIndicator === value);
    setData(newDataShow);
  };

  const monthsHeader = getMonthsHeader(type);

  const columns = [
    {
      title: "I",
      dataIndex: "index",
      key: "index",
      align: "center",
      fixed: "left",
      width: 10,
      ellipsis: true,
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: "Mã",
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      width: "7%",
      fixed: "left",
      ellipsis: true,
      render: (data) => <b>{data}</b>,
    },
    {
      title: "Quỹ",
      dataIndex: "fund_code",
      key: "fund_code",
      ellipsis: true,
      render: (data) => <b style={{ width: "30px" }}>{data}</b>,
    },
    ...monthsHeader,
    {
      title: "C",
      dataIndex: "growIndicator",
      key: "growIndicator",
      align: "center",
      fixed: "right",
      width: "7%",
      ellipsis: true,
      sorter: (a, b) => a.growIndicator - b.growIndicator,
      sortOrder: sortedInfo.columnKey === "growIndicator" ? sortedInfo.order : null,
    },
    {
      title: "Chart",
      dataIndex: checked ? "stock_code" : "index",
      key: checked ? "stock_code" : "index",
      align: "center",
      ellipsis: true,
      fixed: "right",
      width: "15%",
      render: (item) => {
        let rowData = [];
        if (checked) {
          rowData = data.find((e) => e.stock_code === item);
        } else {
          rowData = data[item - 1];
        }
        const dataChart = {
          xAxis: [],
          series: [],
        };

        for (let i = 1; i <= 11; i++) {
          dataChart.xAxis.push(`T${i}`);
          if (rowData && rowData[i] && rowData[i][type]) {
            if (type === queryTypesSample.portfolio_rate) dataChart.series.push(Math.floor(rowData[i][type] * 100));
            else if (type === queryTypesSample.quantity) dataChart.series.push(Math.floor(rowData[i][type] / 1000));
            else dataChart.series.push(Math.floor(rowData[i][type] / 1000));
          } else dataChart.series.push(1);
        }

        return (
          <EChartCustom
            data={dataChart}
            type={chartType}
            style={{ height: isMobileView ? "7rem" : "5rem", width: isMobileView ? "20vw" : "10vw" }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Flex gap={isMobileView ? 6 : 12} align="center" style={{ flexWrap: "wrap", width: "100%", margin: "0.75rem 0" }}>
        <Select
          allowClear
          showSearch
          placeholder="Quỹ đầu tư"
          onChange={(value) => setFundSelected(value)}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          options={fundsContext}
          style={{ width: 250 }}
        />
        <InputNumber defaultValue={defaultYear} onChange={(value) => setYear(value)} />
        <Input
          placeholder="Mã cổ phiếu"
          style={{ width: 150, fontWeight: "400" }}
          onPressEnter={(e) => setSearchValue(e.target.value)}
        />
        <Select
          placeholder="Loại thông tin"
          value={type}
          onChange={(value) => setType(value)}
          options={queryTypes}
          disabled={checked}
          style={{ width: 150 }}
        />
        <Select
          placeholder="Loại biểu đồ"
          defaultValue={"line"}
          onChange={(value) => setChartType(value)}
          options={chartTypes}
          style={{ width: 100 }}
        />
        <Checkbox checked={checked} onChange={handleCheckedChange}>
          Nhóm theo mã
        </Checkbox>
        <InputNumber placeholder="Tăng liên tục" onChange={handleFilterMonthIncrement} />
        <Flex gap={12} align="center" justify="end" style={{ flexGrow: 1 }}>
          {loading && <Spin />}
          <span style={{ fontWeight: 600 }}>Tổng: {data.length}</span>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={true}
        scroll={{
          x: "max-content",
          y: "80vh",
        }}
      />
    </>
  );
};
export default AdvancePortfolios;
