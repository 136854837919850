import { useState, useEffect, useCallback } from "react";
import { Table, Select, Space, InputNumber, Input, Checkbox, Spin, Flex } from "antd";
import { fetchDataWithQuery, fetchDataWithGroupOrder } from "../../../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage, formatDateToShow } from "../../../../services/common";
import { mongoCollections } from "../../../../utils/mongoScript";
import { useFundsContext } from "../../../../context/FundsContext";
import { all_stocks_data } from "../../../../utils/data";

const ListIncome = ({ isReload }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});

  const { fundsContext } = useFundsContext();

  // filter option
  const defaultMonth = new Date().getMonth();
  const defaultYear = new Date().getFullYear();

  const [fundSelected, setFundSelected] = useState(null);
  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = useState(false);

  const fetchDataFromRealm = useCallback(async (fundSelected, year, month, searchValue) => {
    try {
      let queryObject = {};

      // if (fundSelected) queryObject = { ...queryObject, fund_code: fundSelected };
      // if (year) queryObject = { ...queryObject, year };
      // if (month) queryObject = { ...queryObject, month };
      if (searchValue) queryObject = { ...queryObject, stock_code: { $regex: searchValue, $options: "i" } };

      setLoading(true);
      const result = await fetchDataWithQuery(mongoCollections.income, queryObject);

      if (result.length === 0) {
        setData([]);
        return;
      }

      const converedData = result.map((e, index) => {
        return {
          ...e,
          index,
          key: index.toString(),
        };
      });
      setData(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchGroupDataFromRealm = useCallback(async (year, month, searchValue) => {
    try {
      let queryObject = [
        { $match: { year: year, month: month } },
        {
          $group: {
            _id: {
              stock_code: "$stock_code",
              month: "$month",
              year: "$year",
            },
            quantity: { $sum: "$quantity" },
            value: { $sum: "$value" },
            portfolio_rate: { $sum: "$portfolio_rate" },
            organizations: {
              $push: {
                fund_code: "$fund_code",
              },
            },
            count: { $sum: 1 },
          },
        },
      ];

      if (searchValue) {
        queryObject[0].$match.stock_code = { $regex: searchValue, $options: "i" };
      }

      setLoading(true);
      const result = await fetchDataWithGroupOrder(mongoCollections.income, queryObject);
      if (result.length === 0) {
        setDataGroup([]);
      }

      const converedData = result.map((e, index) => {
        const stock = all_stocks_data.find((s) => s.Symbol === e._id.stock_code);

        return {
          key: index.toString(),
          index: index + 1,
          stock_code: e._id.stock_code,
          count: e.count,
          organizations: e.organizations.map((e) => e.fund_code).join(", "),
          quantity: e.quantity,
          value: e.value,
          portfolio_rate: e.portfolio_rate,
          company_name: stock.CompanyName ?? "",
          category: stock.CategoryName ?? "",
        };
      });

      setDataGroup(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (checked) {
      fetchGroupDataFromRealm(year, month, searchValue);
    } else {
      fetchDataFromRealm(fundSelected, year, month, searchValue);
    }
  }, [isReload, fundSelected, month, year, searchValue, checked, fetchDataFromRealm, fetchGroupDataFromRealm]);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "6%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: "Nguồn thu",
      dataIndex: "type_code",
      key: "type_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      render: (string) => formatDateToShow(string),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      render: (string) => formatNumberWithCommas(string),
    },
    {
      title: "Note",
      dataIndex: "description",
      key: "description",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
    },
  ];

  const columns_group = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "5%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: "Mã",
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      width: "7%",
      fixed: "left",
    },
    {
      title: "Ngành",
      dataIndex: "category",
      key: "category",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      sorter: (a, b) => {
        return a.category ? a.category.localeCompare(b.category) : 1;
      },
      sortOrder: sortedInfo.columnKey === "category" ? sortedInfo.order : null,
    },
    {
      title: "Tần suất",
      dataIndex: "count",
      key: "count",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.count - b.count,
      sortOrder: sortedInfo.columnKey === "count" ? sortedInfo.order : null,
    },
    {
      title: "Quỹ",
      dataIndex: "organizations",
      key: "organizations",
      align: "left",
      width: "24%",
      ellipsis: true,
    },
    {
      title: "Tổng SL",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === "quantity" ? sortedInfo.order : null,
      render: (numberString) => formatNumberWithCommas(Math.floor(numberString / 1000000)),
    },
    {
      title: "Tổng GT",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.value - b.value,
      sortOrder: sortedInfo.columnKey === "value" ? sortedInfo.order : null,
      render: (numberString) => formatNumberWithCommas(Math.floor(numberString / 1000000000)),
    },
    {
      title: "Tổng TT",
      dataIndex: "portfolio_rate",
      key: "portfolio_rate",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.portfolio_rate - b.portfolio_rate,
      sortOrder: sortedInfo.columnKey === "portfolio_rate" ? sortedInfo.order : null,
      render: (numberString) => formatToPercentage(numberString),
    },
    {
      title: "Tên dầy đủ",
      dataIndex: "company_name",
      key: "company_name",
      align: "left",
      ellipsis: true,
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <Space size={12}>
          <Select
            allowClear
            showSearch
            placeholder="Quỹ đầu tư"
            onChange={(value) => setFundSelected(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fundsContext}
            style={{ width: 250 }}
            disabled={checked}
          />
          <InputNumber defaultValue={defaultYear} onChange={(value) => setYear(value)} />
          <InputNumber min={1} max={12} defaultValue={defaultMonth} onChange={(value) => setMonth(value)} />
          <Input
            placeholder="Mã cổ phiếu"
            size="small"
            style={{ height: "2rem", fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
          <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
            Thống kê tần suất
          </Checkbox>
          {loading && <Spin />}
        </Space>

        <h4>Tổng: {checked ? dataGroup.length : data.length}</h4>
      </Flex>
      <Table
        columns={checked ? columns_group : columns}
        dataSource={checked ? dataGroup : data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          y: 550,
        }}
      />
    </>
  );
};
export default ListIncome;
