import { useState } from "react";
import { Card, Button, Tabs } from "antd";
import ListPortfolios from "./ListPortfolios";
import AddPortfolios from "./AddPortfolios";
import TimePortfolios from "./TimePortfolios";
import AdvancePortfolios from "./AdvancePortfolios";
import useWindowSize from "../../../hooks/useWindowSize";

const FundsPortfolio = () => {
  const { isMobileView } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const [isReloadList, setIsReloadList] = useState(false);

  const handleCloseModal = (isReload) => {
    if (isReload) {
      setIsReloadList((prevIsReloadList) => !prevIsReloadList);
    }
    setIsVisible(false);
  };

  const items = [
    {
      key: "1",
      label: "Phân tích theo tháng",
      children: <AdvancePortfolios isReload={isReloadList} />,
    },
    {
      key: "2",
      label: "Phân tích chung",
      children: <ListPortfolios isReload={isReloadList} />,
    },

    {
      key: "3",
      label: "Phân tích chi tiết",
      children: <TimePortfolios isReload={isReloadList} />,
    },
  ];

  return (
    <Card
      bordered={false}
      title="Danh mục đầu tư các quỹ"
      styles={{ body: { padding: 0 } }}
      extra={
        <Button size="small" onClick={() => setIsVisible(true)}>
          Import
        </Button>
      }>
      {isVisible && <AddPortfolios isOpenModal={isVisible} onClose={handleCloseModal} />}

      <Tabs
        size="small"
        defaultActiveKey="1"
        items={items}
        style={{ padding: isMobileView ? "0 0.25rem" : "0 1.5rem", margin: 0 }}
        tabBarStyle={{ margin: 0 }}
      />
    </Card>
  );
};

export default FundsPortfolio;
