import { useState, useEffect, useCallback } from "react";
import { Table, Space, InputNumber, Input, Spin, Flex, Card, Badge, Button } from "antd";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { fetchDataWithGroupOrder } from "../../../../services/realmServices";
import { formatNumberWithCommas } from "../../../../services/common";
import { all_stocks_data } from "../../../../utils/data";
import { colors } from "../../../../utils/colors";

const PortfolioStatistic = ({ stock, onSelectStock }) => {
  const [loading, setLoading] = useState(true);
  const [dataGroup, setDataGroup] = useState([]);
  const [selectedStock, setSelectedStock] = useState(stock);

  // filter option
  const defaultMonth = new Date().getMonth();
  const defaultYear = new Date().getFullYear();

  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);
  const [searchValue, setSearchValue] = useState("");

  const fetchGroupDataFromRealm = useCallback(async (year, month, searchValue) => {
    try {
      let queryObject = [
        { $match: { year: year, month: month } },
        {
          $group: {
            _id: {
              stock_code: "$stock_code",
              month: "$month",
              year: "$year",
            },
            quantity: { $sum: "$quantity" },
            value: { $sum: "$value" },
            portfolio_rate: { $sum: "$portfolio_rate" },
            organizations: {
              $push: {
                fund_code: "$fund_code",
              },
            },
            count: { $sum: 1 },
          },
        },
        { $sort: { count: -1, value: -1 } },
      ];

      if (searchValue) {
        queryObject[0].$match.stock_code = { $regex: searchValue, $options: "i" };
      }

      setLoading(true);
      const result = await fetchDataWithGroupOrder("funds_portfolio", queryObject);
      if (result.length === 0) {
        setDataGroup([]);
      }

      const converedData = result.map((e, index) => {
        const stock = all_stocks_data.find((s) => s.Symbol === e._id.stock_code);

        return {
          key: index.toString(),
          index: index + 1,
          stock_code: e._id.stock_code,
          count: e.count,
          organizations: e.organizations.map((e) => e.fund_code).join(", "),
          quantity: e.quantity,
          value: e.value,
          portfolio_rate: e.portfolio_rate,
          category: stock.CategoryName ?? "",
        };
      });

      setDataGroup(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleStockChange = (value) => {
    setSelectedStock(value);
    onSelectStock(value);
  };

  useEffect(() => {
    fetchGroupDataFromRealm(year, month, searchValue);
  }, [month, year, searchValue, fetchGroupDataFromRealm]);

  const columns_group = [
    {
      title: <span className="text-secondary-color">I</span>,
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: 60,
      render: (_text, _record, index) => {
        if (index <= 2) return <Badge style={{ fontWeight: 700 }} count={index + 1} color={colors[index]} />;
        else return <b>{index + 1}</b>;
      },
    },
    {
      title: <span className="text-secondary-color">Mã</span>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">Ngành</span>,
      dataIndex: "category",
      key: "category",
      align: "left",
      width: 120,
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">SL Quỹ</span>,
      dataIndex: "count",
      key: "count",
      align: "center",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">SLCP (n)</span>,
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      ellipsis: true,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000))}</b>,
    },
    {
      title: <span className="text-secondary-color">Giá trị (tỉ)</span>,
      dataIndex: "value",
      key: "value",
      align: "center",
      ellipsis: true,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000000000))}</b>,
    },
    {
      title: <span className="text-secondary-color">A</span>,
      dataIndex: "stock_code",
      align: "center",
      ellipsis: true,
      width: 60,
      render: (value) => (
        <Button
          onClick={() => handleStockChange(value)}
          size="small"
          type={selectedStock !== value ? "text" : "primary"}
          shape="circle"
          icon={selectedStock === value ? <EyeFilled /> : <EyeOutlined />}
        />
      ),
    },
  ];

  return (
    <Card>
      <Flex justify="space-between" align="end">
        <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Thống kê tần suất</span>
        <Space size={12}>
          <InputNumber defaultValue={defaultYear} onChange={(value) => setYear(value)} />
          <InputNumber min={1} max={12} defaultValue={defaultMonth} onChange={(value) => setMonth(value)} />
          <Input
            placeholder="Tìm kiếm..."
            size="small"
            style={{ height: "2rem", fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
          {loading && <Spin />}
        </Space>
      </Flex>
      <br />
      <Table
        columns={columns_group}
        dataSource={dataGroup}
        className="ant-border-space"
        pagination={false}
        scroll={{
          y: "70vh",
        }}
      />
    </Card>
  );
};
export default PortfolioStatistic;
