const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formatToPercentage = (number) => {
  return `${(number * 100).toFixed(2)}`;
};

const getStocksDataFromCafeF = async () => {
  const totalPages = 20;
  const data = [];

  let headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
    Origin: "https://s.cafef.vn",
    Referer: "https://s.cafef.vn",
    credentials: "include",
  };

  try {
    for (let i = 0; i < totalPages; i++) {
      const skip = i * 20;
      const url = `https://s.cafef.vn/ajax/pagenew/databusiness/congtyniemyet.ashx?centerid=1&skip=${skip}&take=20&major=0`;

      const { Data, Error } = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (Error) {
        console.log(Error);
      } else {
        data.push(Data);
      }
    }

    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

export const formatISOToUTC7 = (isoString) => {
  const date = new Date(isoString);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const excelDateToJSDate = (excelSerial) => {
  const excelEpoch = new Date(1900, 0, 1);
  const daysOffset = excelSerial - 2;
  return new Date(excelEpoch.getTime() + daysOffset * 24 * 60 * 60 * 1000);
};

export const formatDateToJSDateToShow = (string) => {
  const date = excelDateToJSDate(string);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const formatDateToShow = (string) => {
  const date = new Date(string);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export { formatNumberWithCommas, formatToPercentage, getStocksDataFromCafeF };
