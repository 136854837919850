import { createContext, useState, useContext, useEffect, useCallback } from "react";
import { getAllFunds } from "../services";

const FundsContext = createContext();

export const FundsProvider = ({ children }) => {
  const [fundsContext, setFundsContext] = useState();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getAllFunds();

      const conveteddata = data.map((e) => {
        return {
          value: e.fund_code,
          label: `${e.organization_name} - ${e.fund_code}`,
          ...e,
        };
      });

      setFundsContext(conveteddata);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FundsContext.Provider
      value={{
        fundsContext,
      }}>
      {children}
    </FundsContext.Provider>
  );
};

export const useFundsContext = () => useContext(FundsContext);
